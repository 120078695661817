﻿.admin {
    padding: 15px 0;
    position: relative;

    h1 {
        margin-top: 0;
        margin-bottom: 2em;
        font-size: 1.5em;
        font-weight: 400;
        text-align: left;
        border-left: 2px solid $text-color;
        padding-left: 15px;
    }

    @include breakpoint(md) {
        padding: 1.5em;
    }   
}