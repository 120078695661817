﻿html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 100%;
}

body {
    height: 100%;
    font-family: "Proxima Nova";
    font-weight: 400;
    font-size: 16px;
    line-height: 1.618;
    background-color: $site-background-color;
    color: $text-color;
    min-width: 303px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    @include vendor-prefix(box-sizing, border-box);
    @include vendor-prefix(user-select, none);
}

*:focus {
    outline: none !important;
    @include box-shadow(none);
}

*::-moz-focus-inner {
    border: 0 !important;
    @include box-shadow(none);
}

