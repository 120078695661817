﻿.error-page {
    position: relative;

    img {
        display: block;
        right: 0;
        top: 0;
        position: absolute;
        opacity: 0.03;
    }

    .container {
        max-width: 420px;
        padding: $padding-large-vertical * 10 0;
        text-align: center;

        h1 {
            font-size: 120px;
            margin: 0;
        }

        .btn {
            margin-top: $padding-large-vertical * 6;
        }

        .page-url {
            font-size: 16px !important;
            color: $brand-danger;
        }
    }
}
