﻿@import "../base/vars";

#relatorio_vendas {
    label {
        color: lighten($text-color, 32%);
        min-width: 100px;
    }

    .report {
        margin-top: 30px;
    }

    .report-title {
        border-top: 2px solid $black;
        text-align: center;
        padding: 15px 0;
    }

    .resumo {
        border-left: 2px solid $black;
        padding-left: 15px;

        span {
            width: 150px;
            display: inline-block;
        }

        h4 {
            color: $primary-color;
        }
    }

    .pedido-data {
        margin: 75px 0;
    }

    .pedido-header {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: -15px;
            width: 30px;
            height: 4px;
            background-color: $primary-color;
        }
    }
}
