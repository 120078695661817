﻿@import "../base/vars";

footer {
    width: 100%;
    
    padding: $padding-base-horizontal * 2;
    background-color: #2E3234;
    border-top: 4px solid $gray-lighter;
    color: #747474;
    text-align: center;

    @include breakpoint(md) {
        text-align: left;
    }

    .col {
        margin-bottom: $def-v-margin * 3;
        padding: 0 40px;
    }

    @include breakpoint(md) {
        .col {
            margin-bottom: 0;
        }
    }

    &:before {
        content: "";
        clear: both;
        width: 100%;
        height: 1px;
        display: table;
    }

    h3 {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin-bottom: 2em;

        li {
            margin-bottom: 8px;

            a, b {
                color: #747474;
                font-weight: 600;
                text-decoration: none !important;

                &:hover, &:active, &:focus {
                    color: #f2f2f2;
                }
            }
        }
    }
}
