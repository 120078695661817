﻿.atendimento {

  .nav-tabs {
    margin-bottom: 3em;

    @include breakpoint(sm) {
      border-right: 3px solid $gray-lighter;
      padding-right: 15px;
    }
  }

  .tab-content {
    padding: 2em;
    border: none;
    .title {
      border: 0;
      margin-bottom: 15px;
      padding-bottom: 15px;
      font-size: 1.3em;
    }
  }
}
