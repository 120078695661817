﻿#about{
    h6{
        text-align: center;
        margin-top: 45px;
    }
    h1{margin-top: 45px;}
    p{
        margin: 0 175px;
        text-align: justify;
        line-height: 28px;
    }
}
