﻿
input[type=text],
input[type=email],
input[type=number],
input[type=password],
textarea,
button,
select,
.input {
    width: 100%;
    padding: 13px 10px;
    border: 2px solid $border-color;
    border-radius: 4px;
    outline: none;
    background-color: $white;
    @include vendor-prefix(transition, all 0.3s);
    -webkit-appearance: none;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 400;
    outline: none;

    &:focus:not(:active) {
        border-color: $primary-color;
    }

    &:disabled {
        opacity: 0.5;
    }
}

button {
    width: auto;
}

textarea {
    line-height: 1.618em;
    height: 12em;
}

textarea.no-resize{
    resize: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

select {
    background-image: url(/Images/icon-select.svg);
    background-position: 100% center;
    background-repeat: no-repeat;
    background-size: auto 38%;
    padding: 12px 10px !important;
    padding-right: 20px !important;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

[data-val-required], [data-val-required] {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4xLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0iI0U5NEIzNSIgY3g9IjIiIGN5PSIxOCIgcj0iMiIvPg0KPC9zdmc+DQo=') /*../Images/icon-required.svg*/;
    background-position: top right;
    background-repeat: no-repeat;
}

.editor-label {
    width: 1%;
    position: relative;
    float: left;
    margin-bottom: 15px;

    label {
        width: auto;
        padding: 0 0.5em;
        position: absolute;
        display: inline-block;
        color: lighten($text-color, 32%);
        left: 32px;
        top: 14px;
        margin: 0;
        z-index: 10;
        pointer-events: none;
        font-size: 15px;
        @include vendor-prefix(transition, all 0.2s ease-in-out);
        text-wrap: none;
        white-space: nowrap;
    }

    &.floating {
        label {
            background-color: $white;
            max-width: none;
            top: -0.9em;
            @include vendor-prefix(transition, all 0.2s ease-in-out);
        }
    }
}

.editor-field {
    width: 99%;
    margin-bottom: 1.3em;
    position: relative;
    float: left;

    &.icon, &.fa {
        input, textarea, select {
            padding-left: 2.5em;
        }
    }

    &:before {
        position: absolute;
        width: 1.8em;
        text-align: center;
        left: 0;
        height: 100%;
        line-height: 2.78em;
        font-size: 1.2em;
        color: #caced9;
        z-index: 10;
        pointer-events: none;
    }

    input, textarea, select {
        width: 101%;
        font-size: 15px;
        padding: 15px 15px;
        margin-left: -1%;
    }

    textarea {
        padding-top: 0.75em;
    }

    .field-validation-error {
        display: inline-block;
        float: right;
        text-transform: uppercase;
        font-weight: 400;
        font-family: $font-family-base;
        padding: 0.5em;
        color: $red;
        text-align: right;
        margin-top: 0.5em;
        font-size: 0.8em;
    }
}

form {
    .errors {
        width: 100%;
        float: left;

        li {
            list-style: none;
            text-align: center;
            text-transform: uppercase;
            padding: 2em 0.5em 0.5em;
            color: $red;
            margin-bottom: 0.5em;
        }
    }
}
