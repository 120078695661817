﻿
.alertify-log {
    text-shadow: none !important;
}

.alertify-log-error {
    background-color: $red !important;
}

.alertify-log-success {
    background-color: $green !important;
}