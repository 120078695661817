
@media only screen and (min-width: 768px) {
    .zoomContainer {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .zoomContainer {
        display: none;
    }
}
