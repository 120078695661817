﻿$test-color: #fbea84;

$primary-color: #2D314C;
$secondary-color: #2D314C;
$red: #ed6060;
$green: #62cea9;
$text-color: #333;
$white: #fff;
$black: #121212;
$site-background-color: #F4F2F0;
$background-color: #fff;
$info-color: #fffabf;

$def-v-margin: 15px;
$def-h-margin: 15px;
$def-input-h: 2.694em;

$cart-section-w: 22em;