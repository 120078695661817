﻿@import "../base/vars";


@media only screen and (min-width: 768px){
    #main{
       // margin-top: 95px; //altura do header no site
    }
}

@media only screen and (max-width: 768px){
    #main{
      //  margin-top: 172px; //altura do header no site
    }
}



.page {
    @extend %box;
    margin-top: 15px;
    padding: 30px;
    padding-bottom: 2em;
    background-color: $background-color;

    .title {
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 1.5em;
        margin-top: 0.5em;
        border-bottom: 2px solid $gray-lighter;
        padding-bottom: 15px;
    }
}

.page-text {
    max-width: 680px;
    margin: 0 auto;
    margin-top: $padding-large-vertical * 4;
    padding: $padding-large-horizontal * 3 $padding-large-horizontal * 5;
    line-height: 1.618;
    font-weight: 400;

    ul, ol {
        padding-left: 15px;
        margin-bottom: 2em;
    }

    ol {

        li {
            margin-top: 15px;
        }

        ul {
            list-style: none;

            li {
                margin-top: 15px;
            }
        }
    }

    h1, h2, h3 {
        font-weight: 400;
    }
}

.page-text-lg {
    max-width: 960px;
}

.page-text-sm {
    max-width: 512px;
    padding: $padding-large-horizontal * 2 $padding-large-horizontal * 2;
}

.page-container {
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;
}

@mixin breakpoint($point) {
    @if $point == lg {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $point == md {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $point == sm {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $point == xsm {
        @media (max-width: 767px) {
            @content;
        }
    }
}

@media (max-width: 767px) {
    .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12 {
        float: left;
    }

    .col-xsm-12 {
        width: 100%;
    }

    .col-xsm-11 {
        width: 91.66666667%;
    }

    .col-xsm-10 {
        width: 83.33333333%;
    }

    .col-xsm-9 {
        width: 75%;
    }

    .col-xsm-8 {
        width: 66.66666667%;
    }

    .col-xsm-7 {
        width: 58.33333333%;
    }

    .col-xsm-6 {
        width: 50%;
    }

    .col-xsm-5 {
        width: 41.66666667%;
    }

    .col-xsm-4 {
        width: 33.33333333%;
    }

    .col-xsm-3 {
        width: 25%;
    }

    .col-xsm-2 {
        width: 16.66666667%;
    }

    .col-xsm-1 {
        width: 8.33333333%;
    }

    .col-xsm-pull-12 {
        right: 100%;
    }

    .col-xsm-pull-11 {
        right: 91.66666667%;
    }

    .col-xsm-pull-10 {
        right: 83.33333333%;
    }

    .col-xsm-pull-9 {
        right: 75%;
    }

    .col-xsm-pull-8 {
        right: 66.66666667%;
    }

    .col-xsm-pull-7 {
        right: 58.33333333%;
    }

    .col-xsm-pull-6 {
        right: 50%;
    }

    .col-xsm-pull-5 {
        right: 41.66666667%;
    }

    .col-xsm-pull-4 {
        right: 33.33333333%;
    }

    .col-xsm-pull-3 {
        right: 25%;
    }

    .col-xsm-pull-2 {
        right: 16.66666667%;
    }

    .col-xsm-pull-1 {
        right: 8.33333333%;
    }

    .col-xsm-pull-0 {
        right: auto;
    }

    .col-xsm-push-12 {
        left: 100%;
    }

    .col-xsm-push-11 {
        left: 91.66666667%;
    }

    .col-xsm-push-10 {
        left: 83.33333333%;
    }

    .col-xsm-push-9 {
        left: 75%;
    }

    .col-xsm-push-8 {
        left: 66.66666667%;
    }

    .col-xsm-push-7 {
        left: 58.33333333%;
    }

    .col-xsm-push-6 {
        left: 50%;
    }

    .col-xsm-push-5 {
        left: 41.66666667%;
    }

    .col-xsm-push-4 {
        left: 33.33333333%;
    }

    .col-xsm-push-3 {
        left: 25%;
    }

    .col-xsm-push-2 {
        left: 16.66666667%;
    }

    .col-xsm-push-1 {
        left: 8.33333333%;
    }

    .col-xsm-push-0 {
        left: auto;
    }

    .col-xsm-offset-12 {
        margin-left: 100%;
    }

    .col-xsm-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xsm-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xsm-offset-9 {
        margin-left: 75%;
    }

    .col-xsm-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xsm-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xsm-offset-6 {
        margin-left: 50%;
    }

    .col-xsm-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xsm-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xsm-offset-3 {
        margin-left: 25%;
    }

    .col-xsm-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xsm-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xsm-offset-0 {
        margin-left: 0;
    }
}