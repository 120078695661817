﻿@import "../base/vars";

#revendedores {

    .info-revendedor{
        display: inline-block;
        margin: 30px;
        vertical-align: top;

        .name{
            font-weight: bold;
        }

        .email{
            border-bottom: 2px solid $black;
            padding-bottom: 10px;
        }

    }

    .chart-container {
        display: inline-block;

        tr {
            height: 30px;
        }

        td span {
            font-family: 'Roboto', sans-serif;
            font-size: 11px;
            color: #888;
        }

        .progress-wrap {
            background-color: rgba(0, 0, 0, 0.1);
            height: 5px;
            width: 200px;
            margin: 0 10px;
        }

        .progress-bar {
            height: 5px;
            background-color: $primary-color;
        }

        .currency {
            text-align: left;
        }
    }

    .lista-pedidos {
        display: inline-block;
        text-align: center;
        width: 250px;
        overflow: auto;
        max-height: 300px;

        h3 {
            border-bottom: 1px solid darken($site-background-color, 3%);
        }

        table {
            width: 100%;

            td {
                width: 50%;
            }
        }

        .pedido-data {
            color: $primary-color;
            font-size: 18px;
            text-align: right;
            padding-right: 5px;
        }

        .pedido-total {
            text-align: left;
            padding-left: 5px;
        }
    }

    .total{
        font-size: 14px;
        margin-top: 15px;
    }
}
