﻿@import "../base/vars";

.caracteristicas {

    h3 {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin-bottom: 2em;

        li {
            margin-bottom: 8px;

            a, b {
                color: #747474;
                font-weight: 600;
                text-decoration: none !important;

               
            }
        }
    }
}



.produto {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    .col {
        margin-bottom: 15px;
    }

    .slider {
        @extend %box;
        position: relative;
        background-color: transparent;
        border: none;

        .zoomWrapper {
            max-width: 100%;
            overflow: hidden;
            text-align: left;
            pointer-events: none;

            @include breakpoint(md) {
                pointer-events: all;
            }

            img {
                width: 100% !important;
                max-width: 420px;
                border: 2px solid darken($body-bg, 10%);
                height: auto !important;
                display: block;
                position: relative !important;
            }
        }

        .thumbs {
            .thumb {
                border: 2px solid darken($body-bg, 10%);
                width: 68px;
                height: 68px;
                display: block;
                border-radius: 3px;
                margin: 0;
                margin-bottom: 10px;
                float: left;

                @include breakpoint(sm) {
                    float: none;
                }

                @include opacity(0.7);
                @include transition(opacity 0.2s);

                &:hover, &.active {
                    @include opacity(1);
                    border-color: $brand-primary;
                }
            }
        }
    }

    .title {
        text-align: left;
        color: lighten($text-color, 10%);
        font-weight: 500;
        line-height: 1.6em;
        font-size: 1.5em;
        text-transform: uppercase;
        margin: 0.5em 0 0;
        margin-bottom: 30px;

        span {
            font-size: 0.8em;
            color: $brand-primary;
        }
    }

    .buy-cont {
        @extend %box;
        padding: 30px;
        position: relative;
        background-color: transparent;
        border: none;

        @include breakpoint(md) {
            border-left: 1px solid $border-color;
        }

        $item-size: 58px;

        .colors, .sizes {
            margin-bottom: 15px;

            h3 {
                font-size: 14px;
                padding-left: 15px;
                color: $text-muted;
                font-weight: 400;
                text-transform: uppercase;
            }

            li {
                list-style: none;
                float: left;
                margin-top: 5px;
                margin-bottom: 5px;
                padding-right: 15px;
            }

            label {
                width: $item-size;
                height: $item-size;
                cursor: pointer;
                display: block;
                float: left;
                margin: 0;
                margin-bottom: 0;

                .switcher {
                    width: 100%;
                    height: 100%;
                    display: block;
                    line-height: 54px;
                    text-align: center;
                    font-weight: 500;
                    border-radius: 3px;
                    overflow: hidden;
                    border: 3px solid transparent;
                    background-color: $white;
                    @include vendor-prefix(transition, all 0.2s);

                    &:hover {
                        border-color: $border-color;
                    }
                }

                input[type=radio] {
                    display: none;

                    &:checked ~ .switcher {
                        border-color: $primary-color;
                        opacity: 1;
                    }
                }
            }
        }

        .colors {

            .color {
                border-radius: 0;
                @include vendor-prefix(transform, none);

                span {
                    width: 100%;
                    height: 100%;
                    display: block;
                    float: left;
                }

                span.half {
                    width: 50%;
                }
            }
        }

        .sizes {
            margin-top: $padding-large-vertical * 4;
        }

        .product-title {
            padding-left: 15px;

            b {
                font-weight: 600;
            }
        }
    }

    .add-cont {
        margin-top: 3em;

        .add-txt-cont {
            margin-bottom: $padding-large-vertical * 2;

            .price {
                font-size: 20px;
                text-align: left;
                font-weight: 700;
                display: block;
                color: $primary-color;
                padding: 14px 0;
                padding-left: 10px;
                line-height: 1;
            }

            @include breakpoint(sm) {
                .price, input {
                    width: 50%;
                    float: left;
                }
            }
        }

        .add-btn-cont {
            margin-top: $padding-large-vertical;

            .btn {
                display: block;
                margin-bottom: 5px;
                line-height: 1;

                .icon {
                    margin-right: 3px;
                }
            }

            .btn-group {
                margin-bottom: $padding-large-vertical * 4;
            }
        }

        .btn-buy {
            background-color: $brand-primary;
            color: $white;

            &:hover, &:active, &:focus {
                color: $white !important;
                background-color: lighten($brand-primary, 7%) !important;
                ;
            }
        }

        .btn-checkout {
            &:hover, &:active, &:focus {
                background-color: lighten($brand-primary, 35%);
                color: $brand-primary !important;
            }
        }

        @include breakpoint(xsm) {
            font-size: 0.8em;
            margin-top: 15px;

            .add-btn-cont {
                margin-top: 15px;
            }
        }
    }

    .description {
        @extend %box;
        margin-top: 2em;
        font-size: 0.9em;
        padding: 2em;
        background-color: transparent;
        border: none;
        border-top: 1px solid $border-color;

        .description-title {
            margin-bottom: $padding-large-vertical * 4;
            margin-top: 0;
            font-size: 16px;
            font-weight: 600;
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: 300;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }
    }
}

.zoomWindow {
    border: none !important;
    @include box-shadow(0 2px 5px rgba(0, 0, 0, 0.16));
}
.zoomLens {
    background-color: #222 !important;
    z-index: 10000 !important;
}

