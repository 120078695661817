﻿.admin-clientes {

    .promotor-cliente-cadastro {
        @extend %box;
        margin: 0;
        padding: $padding-large-horizontal;

        h3 {
            text-align: center;
        }
    }

    .list {
        @extend %box;
        padding: 15px;
        margin-bottom: 2em;
        text-align: center;

        .empty {
            margin: 0;
        }

        ul {
            margin-top: 15px;
            list-style: none;

            li {
                padding: 15px 0;
                border-top: 1px solid $border-color;

                &:first-child {
                    border-top: none;
                }
                .name {
                    font-weight: 600;
                }

                .email {
                    font-size: 14px;
                }
            }
        }
    }


    @include breakpoint(md) {
        .list {
            margin-bottom: 0;
        }
    }
}