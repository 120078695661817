﻿@import "../base/vars";

.checkout {
    background-color: transparent;
    border: none;

    .tabs {
        $active-bg: transparent;

        h2 {
            font-size: 1.5em;
            font-weight: 400;
            margin-bottom: 1.5em;
            color: lighten($text-color, 15%);
            padding-top: 15px;
        }

        .btn {

            &.nav-btn {
                margin-top: 2em;

                i {
                    margin-left: 0.2em;
                    margin-right: 0;
                }
            }
        }

        .left {
            float: left;
        }

        .right {
            float: right;
        }

        .tabs-header {
            position: relative;
            margin-top: $padding-large-vertical * 2;
            border-bottom: 2px solid $border-color;

            li {
                list-style: none;

                &.disabled {
                    pointer-events: none;

                    button {
                        color: $text-muted;
                    }
                }

                button {
                    display: block;
                    border: none;
                    background-color: transparent;
                    margin: 0 auto;
                    text-align: center;
                    color: lighten($text-color, 30%);
                    padding: 15px;
                    font-size: 22px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                &.active {
                    button {
                        color: $text-color;
                    }
                }
            }
        }

        article {
            width: 100%;

            li.tab {
                list-style: none;
                opacity: 0;
                pointer-events: none;
                height: 0;
                width: 0;
                padding: 0;
                overflow: hidden;

                @include breakpoint(sm) {
                    padding: 0 15px 15px;
                }

                @include vendor-prefix(transition, 'opacity 0.3s, transform 0.3s');
                @include vendor-prefix(transform, translateY(-50px));

                &.active {
                    width: 100%;
                    height: auto;
                    background-color: $active-bg;
                    opacity: 1;
                    pointer-events: all;
                    @include vendor-prefix(transform, translateY(0));
                    overflow: visible;
                }
            }
        }

        .tab {
            padding: 15px;
        }

        .revisao {
            @extend %box;
            background-color: #fff;

            table {
                width: 100%;
                font-weight: 400;

                .center {
                    text-align: center;
                }

                thead {
                    //background-color: darken($white, 2%);
                    border-bottom: 2px solid $gray-lighter;
                }

                td, th {
                    padding: 15px;

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }

                .total {

                    span {
                        float: right;
                        width: auto;
                        background-color: $gray-lighter;
                        font-weight: 700;
                        margin-top: 2em;
                        color: $text-color;
                        display: block;
                        padding: 15px;
                        margin-right: -15px;
                    }
                }
            }
        }

        .entrega {

            h3 {
                margin-bottom: 15px;
                margin-top: 2em;
            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    label {
                        color: $text-muted;

                        input {
                            display: none;
                        }
                    }
                }
            }

            .list-group-item {
                cursor: pointer;
                padding: $padding-base-vertical $padding-base-horizontal;
                @include transition(all 0.2s);

                .icon {
                    width: 38px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    border-radius: 19px;
                    color: $text-muted;
                    opacity: 0.5;
                    background-color: darken($gray-lighter, 4%);
                    margin-right: $padding-large-horizontal;
                    @include transform-origin(center center);
                    @include transition(all 0.2s);
                }

                &.active {
                    p i {
                        color: $white;
                        opacity: 1;
                        background-color: darken($green, 18%);
                    }
                }
            }

            &.loading {
                position: absolute;
                opacity: 0.6;
                pointer-events: none;
            }

            .frete {
                position: relative;

                .loading {
                    margin: 0;
                    margin-top: 0.5em;

                    i {
                        font-size: 18px;
                        margin-right: 5px;
                    }

                    padding: $padding-large-vertical $padding-base-horizontal;
                }
            }
        }

        .conta {
            border: none;

            &-col {
                @extend %box;
            }

            .account-success {
                display: none;
            }
        }

        .confirmar {

            h3 {
                margin-bottom: $padding-base-vertical * 5;
                text-align: center;
                text-transform: uppercase;
                font-weight: 700;
            }

            .payment-methods {

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }

                h1, h2, h3 {
                    text-transform: uppercase;
                    font-weight: 700;
                }

                .nav {
                    .col {
                        a {
                            padding: $padding-large-horizontal * 3;
                            display: block;
                            text-align: center;
                            cursor: pointer;
                            color: $text-muted;
                            border: 2px dashed $border-color;
                            border-radius: 8px;

                            &:hover, &.active {
                                color: $text-color;
                                border-color: darken($border-color, 15%);
                            }

                            i {
                                font-size: 48px;
                                display: block;
                            }
                        }
                    }
                }
            }

            .complete-conta-cont {
                .modal {
                    width: 640px;
                }
            }
        }

        @include breakpoint(xsm) {

            .tab {
                //font-size: 0.8em;
            }

            header {
                li {
                    padding: 0 5px;

                    a {
                        padding: 15px 0;
                        text-align: center;

                        i {
                            margin: 0;
                            display: inline;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            table {
                overflow-x: auto;
                display: block;
            }

            article {

                li {
                    padding: 0;

                    &.active {
                        padding: 0;
                    }
                }
            }

            .conta {
                .signin, .signup {
                    padding: 0;
                    padding-top: 1.5em;
                }
            }
        }

        @include breakpoint(md) {
            .conta {
                .separator {
                    display: block;

                    .center {
                        span {
                            background-color: $site-background-color;
                        }
                    }
                }
            }
        }
    }
}

.add-endereco {
    max-width: 512px;

    h2 {
        margin-bottom: $padding-large-vertical * 5;
    }     

    .address-text {
        border-left: 4px solid darken($white, 5%);
        padding: 0 15px;
        margin: 15px 0;
        margin-bottom: 2em;

        h3 {
            color: lighten($text-color, 10%);
            font-size: 1.2em;
        }
    }

    input[type=number] {
        min-width: 8.7em;
        width: 30%;
    }

    @include breakpoint(xsm) {
        .col {
            padding: 0 10px;
        }

        .btn {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}

.aside-header {
    background-color: $gray-lighter;
    border-bottom: 1px solid darken($gray-lighter, 5%);
    padding: $padding-large-vertical * 2 $padding-large-horizontal * 2;
    line-height: 1;
    margin-bottom: $padding-large-vertical * 5;

    h1 {
        text-transform: uppercase;
        font-size: $font-size-h4;
        font-weight: 700;
        text-align: left;
        color: $text-muted;
    }
}

.aside-content {
    padding: 0 $padding-large-horizontal;
    padding-bottom: $padding-large-vertical * 2;

    @include breakpoint(sm) {
        padding: 0 $padding-large-horizontal * 2;
        padding-bottom: $padding-large-vertical * 2;
    }
}

.payment-methods {
    max-width: 960px;
    margin: 0 auto;

    &-total {
        text-align: right;
        display: inline-block;
        color: $text-muted;
        margin: 0.67em 0;
        font-size: $font-size-h4;

        .sum {
            font-size: 14px;
            margin-bottom: 10px;
            display: block;
            color: darken($gray-lighter, 40%);
        }

        .caption {
            text-transform: uppercase;
            font-weight: 700;
            color: $text-color;
        }

        .result {
            font-weight: 700;
            color: $brand-primary;
        }
    }
}

.payment-methods-boleto {
    max-width: 512px;

    .success-message {
        font-weight: 300;
    }
}

.payment-methods-card {

    .credit-card-form {
        padding: $padding-large-horizontal * 1.5;
        width: 512px;
        border-radius: 13px;
        background-color: $gray-lighter;
        margin-bottom: $padding-large-vertical * 3;
        @include box-shadow(0 2px 0 0 #17708e);
        @include gradient-directional(#2973b3 0%, #2ea0d9 100%, -45deg);

        label {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.8);
            margin-top: 12px;
        }

        input {
            border: none;
            font-size: 18px;
            font-weight: 500;
            background-color: transparent;
            padding-left: 0;
            color: #fff;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
            @include placeholder(rgba(255, 255, 255, 0.6));

            &:focus {
                @include placeholder(rgba(255, 255, 255, 0.6));
            }
        }

        .form-group {
            margin-bottom: 0;
        }

        .card-number {
            word-spacing: 30px;
        }

        .card-exp-date {
            .card-exp-month {
                width: 60px;
                padding-right: 0;
            }

            .card-exp-year {
                width: 120px;
                padding-left: 0;
            }
        }
    }

    .aditional-info {
        max-width: 512px;
        margin: 60px 0;
    }
}

.checkout-success {
    margin-top: 200px;
    text-align: center;

    h1 {
    }

    .icon {
        width: 150px;
        height: 150px;
        line-height: 150px;
        font-size: 48px;
        border-radius: 100%;
        text-align: center;
        border: 2px solid $green;
        display: block;
        margin: 50px auto;
        color: $green;
    }
}
