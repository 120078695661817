﻿@import "../base/vars";


@media only screen and (min-width: 768px){
    header.top {
        position: fixed;
    }
    
}

@media only screen and (max-width: 768px){
    header.top {
        position:absolute;
        height: 225px;
    }
}

header.top-fixed {
    position: relative;
    
}

header.top {
    top: 0;
    width: 100%;
    z-index: 10000;
    border-bottom: 1px solid $white;
    background-color: rgba(0, 0, 0, 0.3);
    //@include shadow(1);

    $button-size: 20px;
    $text-color: $white;
    $text-hover-color: $primary-color;

    .page-container {
        position: relative;
    }

    .logo-cont {
        a {
            display: block;
            text-align: center;
           


            img {
                display: inline-block;
                max-height: 95px;
                width: auto;
            }

            @include breakpoint(md) {
                text-align: left;
                padding-left: 15px;
            }

            @include breakpoint(lg) {
                padding-left: 0;
            }
        }
    }

    .header-cont {

        .header-cont-item {
            position: static !important;

            a {
                text-decoration: none !important;
            }

            .header-cont-item-caption {
                display: block;
                font-size: 14px;
                padding: 20px 0;
                line-height: 1;
                color: $text-color;
                cursor: pointer;
                text-align: center;
                @include transition(color 0.3s);

                @include breakpoint(md) {
                    padding: 36px 0;
                }

                &:hover {
                    color: $text-hover-color;
                }

                .icon {
                    font-size: 20px;
                }

                span {
                    margin-left: 5px;
                    display: none;

                    @include breakpoint(sm) {
                        display: inline;
                    }
                }
            }
        }

        .search-cont {
            position: relative !important;
            padding: 15px 30px;

            input[type=text] {
                display: block;
                border: none;
                border-radius: 0;
                background-color: transparent;
                border-bottom: 1px solid $text-color;
                padding: 13px 15px;
                padding-left: 0;
                padding-right: 45px;
                color: $white;
                @include placeholder($text-color);

                &:hover {
                    @include placeholder($text-color);
                    border-bottom-color: $text-color;
                }

                &:focus {
                    @include box-shadow(none);
                    @include placeholder(rgba($text-color, 0.4));
                    border-bottom-color: $text-color;
                }
            }

            button {
                position: absolute;
                right: 30px;
                top: 15px;
                cursor: pointer;
                background-color: transparent;
                border: none;
                width: 45px;

                &:disabled {
                    opacity: 0;
                }

                &:focus {
                    @include vendor-prefix(box-shadow, none);
                }

                &:hover {
                    i {
                        color: $text-hover-color;
                    }
                }

                i {
                    font-size: 16px;
                    font-weight: 100;
                    color: $text-color;
                    @include vendor-prefix(transition, color 0.3s);
                }
            }

            @include breakpoint(md) {
                padding: 24px 15px;

                button {
                    top: 21px;
                    right: 15px;
                }
            }

            .search-dropdown {
                position: absolute;
                top: 90%;
                left: 0;
                background-color: $white;
                padding: 30px 0;
                z-index: 10000;
                width: 100%;
                list-style: none;
                @include box-shadow(0 2px 10px -3px #000);

                @include breakpoint(sm) {
                    border-radius: 3px;
                    left: auto;
                }

                li {
                    a {
                        display: block;
                        padding: 10px 30px;
                        border-left: 3px solid transparent;

                        &:hover {
                            border-left-color: $brand-primary;
                        }

                        img {
                            width: 54px;
                            margin-right: 15px;
                            float: left;
                        }

                        .caption {
                            padding-top: 10px;
                        }

                        .caption, .price {
                            color: #222;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .cart-cont {

            .toggle {
                position: relative;
                display: block;
                margin: 0;

                &:after {
                    top: 117px;
                    left: 50%;
                    @include translate(-50%, 0);
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: transparent;
                    border-bottom-color: lighten($secondary-color, 10%);
                    border-width: 10px;
                    opacity: 0;
                    @include transition(0.3s ease);
                }

                .icon-basket {
                    position: relative;

                    .count {
                        @include vendor-prefix(transition, all 0.2s);
                        $size: 21px;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        color: $white;
                        position: absolute;
                        top: -16px;
                        left: -22px;
                        background-color: $primary-color;
                        width: $size;
                        height: $size;
                        text-align: center;
                        line-height: $size;
                        border-radius: 100%;
                    }
                }
            }

            .dropdown {
                width: 644px;
                min-height: 20em;
                padding-bottom: 15px;
                position: absolute;
                top: 150px;
                right: 15px;
                z-index: 1000;
                opacity: 0;
                pointer-events: none;
                background-color: lighten($secondary-color, 10%);
                @include transition(0.3s ease);

                @include breakpoint(sm) {
                    border-radius: 3px;
                }

                h1 {
                    padding: 20px 2em;
                    margin: 0;
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 15px;
                    font-weight: 400;
                    color: lighten($secondary-color, 45%);

                    i {
                        margin-left: 0.5em;
                    }
                }

                .total {
                    text-align: center;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    font-size: 2em;
                    color: $brand-primary;
                }

                li {
                    list-style: none;
                    padding: 15px;
                    border-bottom: 1px solid lighten($secondary-color, 9%);
                    font-size: 15px;
                    color: lighten($secondary-color, 45%);

                    h2 {
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 0.5em;
                        color: lighten($secondary-color, 45%);
                    }

                    .img-cont {
                        width: 64px;
                        height: 64px;
                        overflow: hidden;
                        border: 4px solid lighten($secondary-color, 15%);
                        border-radius: 32px;
                        background-color: lighten($secondary-color, 5%);
                    }

                    .info {
                        font-size: 0.9em;
                        font-weight: 400;
                        color: lighten($secondary-color, 45%);

                        .info-item {
                            display: inline-block;
                            margin-top: 5px;
                            font-weight: 500;
                            margin-right: $def-h-margin;

                            strong {
                                font-weight: 500;
                                margin-right: 5px;
                            }
                        }
                    }

                    .remove-btn {
                        background-color: transparent;
                        border-color: transparent;
                        padding: 0.5em 0.7em;
                        width: auto;
                        margin-top: 15px;
                        float: right;
                        display: inline-block;
                        color: lighten($secondary-color, 45%);
                        @include vendor-prefix(transition, 'all 0.2s ease-in-out');

                        &:hover {
                            color: $primary-color;
                            //background-color: lighten($secondary-color, 5%);
                        }

                        i {
                            margin: 0;
                            font-size: 15px;
                        }
                    }
                }

                .btn {
                    &:hover, &:active, &:focus {
                        color: $white !important;
                        background-color: $brand-primary !important;
                        ;
                    }
                }
            }

            &.active {

                .dropdown {
                    opacity: 1;
                    top: 100%;
                    pointer-events: auto;
                    cursor: default;

                    @include breakpoint(md) {
                        top: 75px;
                    }
                }

                .toggle {
                    color: $text-hover-color;

                    .count {
                        opacity: 0;
                        top: 4px;
                        left: 4px;
                    }

                    @include breakpoint(md) {
                        &:after {
                            top: 56px;
                            opacity: 1;
                        }
                    }
                }
            }

            &:hover {
                .toggle {
                    i {
                        color: $text-hover-color;
                    }
                }
            }

            &.notify {

                .toggle {
                    .count {
                        @include vendor-prefix(transform, scale(1.5));
                    }
                }
            }

            .empty {
                color: lighten($secondary-color, 45%);
                text-align: center;
                margin-top: 80px;
                font-size: 22px;
            }

            @include breakpoint(xsm) {

                &.active {

                    .toggle {
                        &:after {
                            top: 100%;
                            margin-top: -10px;
                        }
                    }

                    .dropdown {
                        top: 100%;
                        right: 0;
                    }
                }

                .dropdown {
                    width: 100%;

                    li {
                        padding: 0.5em 0;
                        border-right: none;

                        .img-cont {
                            width: 48px;
                            height: 48px;
                            border-radius: 24px;
                        }

                        .remove-btn {
                            margin-top: 0.5em;
                        }
                    }
                }
            }
        }
    }
}