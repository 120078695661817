﻿@import "../base/vars";

.conta {
    position: relative;

    .page-container {
        @extend %box;
    }

    h2, h3 {
        font-weight: 300;
        text-align: center;
        line-height: 15px;
    }

    h2 {
        font-size: 1.2em;
    }

    h3 {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 2em;
    }

    .row {
        height: 100%;
    }

    .col {
        height: 100%;
    }

    .separator {
        width: 100%;
        height: 640px;
        display: none;

        .center {
            width: 2px;
            background-color: $border-color;
            height: 10em;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -0.5px;
            margin-top: -5em;

            span {
                font-size: 1.2em;
                width: 2em;
                height: 2em;
                line-height: 2em;
                background-color: $white;
                text-align: center;
                display: block;
                position: absolute;
                left: -15px;
                top: 50%;
                margin-top: -15px;
                font-style: italic;
            }
        }
    }

    .signin, .signup {
        padding: 15px;
        margin-bottom: 15px;
    }

    .account-success {
        padding-top: 1.5em;
        text-align: center;

        .btn {
            float: none;
        }
    }

    @include breakpoint(xsm) {
        .signin, .signup {
            padding: 0;
            padding-top: 1.5em;
        }
    }

    @include breakpoint(md) {
        .separator {
            display: block;
        }
    }

    .promotor-form {
        display: none;
    }
}
