.busy-indicator {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  display: block;

  &-spinner {
    display: block;
    line-height: 1;
  }

  &-container {
    max-width: 640px;
    left: 50%;
    top: 50%;
    //background-color: #222;
    color: #777;
    padding: $padding-large-horizontal * 2;
    border-radius: $border-radius-base;
    position: absolute;
  }

  &-message {
    padding-top: $padding-large-vertical * 2;
  }
}