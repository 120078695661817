﻿.admin-pedidos {

    .row {
        position: static !important;
    }

    .item {

    }

    .modal-dialog {
        z-index: 10000 !important;
        position: fixed;
        left: 50%;
        top: 0;
        width: 100%;
        max-width: 720px;
        @include translate(-50%, 0);
    }

    .panel-content {
        color: $text-muted !important;
        font-weight: 600;
        height: 310px;
        padding: $padding-large-vertical $padding-large-horizontal;

        .row {
            margin-bottom: 10px;
        }

        span {
            color: $text-color;
            font-weight: 400;
            display: block;
        }
        
    }

    .detail {
        .modal-header {
            text-align: center;
        }

        .modal-body {
            text-align: left !important;
            overflow-y: auto;
        }

        ul {
            list-style: none;
            display: block;
        }

        .item {
            display: block;
            margin-bottom: 3em;
            border-bottom: $border-color;
            padding: 15px 0;
        }
    }
}
