﻿.slide {
    @include vendor-prefix(transition, all 0.3s);
    height: 80px;
    overflow: hidden;
    display: inline-block;
}

    .slide.ng-hide {
        height: 0;
    }

.disable-animations {
    &.ng-enter,
    &.ng-leave,
    &.ng-animate {
        @include transition(none !important)
    }

    &.ng-animate {
        @include animation(none 0s)
    }
}

.ng-hide-add {
    @include animation(0.3s hide)
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ng-hide-remove {
    @include animation(0.3s show)
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-enter,
.animate-leave {
    -webkit-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 400ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    position: relative;
    display: block;
}

    .animate-enter.animate-enter-active,
    .animate-leave {
        opacity: 1;
        top: 0;
        height: 30px;
    }

        .animate-leave.animate-leave-active,
        .animate-enter {
            opacity: 0;
            top: -50px;
            height: 0px;
        }
