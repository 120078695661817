﻿.page-atacadista {

    .images {
        margin-bottom: 5em;
    }

    .img-caption {
        text-align: center;
    }

    .img {
        text-align: center;
        margin: 0 auto;
        width: 162px;
        height: 162px;
        border-radius: 50%;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include box-shadow(0 0 0 5px $gray-lighter);
    }
}
