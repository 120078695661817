﻿.grid-color {
    list-style: none;
    float: left;
    margin: 15px 0;
    margin-left: 10px;
    display: inline-block;

    .color {
        width: 2em;
        height: 2em;
        border-radius: 15px;
        opacity: 0.7;
        @include vendor-prefix(transition, all 0.2s ease-in-out);
        cursor: pointer;
        overflow: hidden;
        display: block;
        @include vendor-prefix(transform-origin, 50% 50%);
        @include vendor-prefix(transform, rotate(-45deg));
        @include shadow(1);

        &:hover, &.active {
            opacity: 1;
            @include hover-border(4px);
        }

        span {
            width: 100%;
            height: 100%;
            display: block;
            float: left;
        }

            span.half {
                width: 50%;
            }
    }
}