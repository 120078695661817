﻿.sitemap{

    margin: 15px 25px;

    li{
        display: inline-block;
        list-style: none;
    }

    & .sitemap-gt{
        font-size: 12px;
        margin: 0 6px;
    }
}
