﻿body.admin {
    font-weight: 400;
    background-color: #f2f2f2 !important;

    @import "base/_vars";
    @import "base/_common";

    @import "layout/header";
    @import "layout/footer";
    @import "layout/common";

    @import "pages/admin";
    @import "pages/admin-clientes";
    @import "pages/admin-conta";
    @import "pages/_admin-pedidos";
}