.home-slider {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1000;
}

.home-img {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover
}

.slider-placeholder {
    display: block;
    height: 100vh;
}

#full-slider {
  opacity: 0;
  overflow: visible !important;
  position: relative;
}

.slides-pagination {
  position: absolute;
  left: 50%;
  bottom: 25px;
  z-index: 10;
  @include translate(-50%, 0);

  a {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin: 0 3px;
    background-color: rgba(255, 255, 255, 0.25);
    float: left;
    display: block;
    text-indent: -100%;
    overflow: hidden;

    &:hover {
      background-color: rgba(255, 255, 255, 0.40);
    }

    &.current {
      background-color: rgba($brand-primary, 0.9);
    }
  }
}