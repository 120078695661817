﻿@import "../base/vars";

.login-cont {
    form {
        max-width: 557px;
        margin: 0 auto;
    }
}

.login, .cadastro {

    .title {
        margin-bottom: 15px;
    }

    .success {
        .btn {
            display: block;
            width: 120px;
            text-align: center;
            margin: 0 auto;
        }
    }
}

.cadastro-cont {

    .tabs {
        list-style: none;
        display: block;
        padding: 15px 0;

        li {
            padding: 0;
            background-color: $gray-lighter;

            label {
                padding: 15px;
                display: block;
                cursor: pointer;
                text-align: center;
                position: relative;
                color: lighten($text-color, 25%);
                white-space: nowrap;
                margin: 0;

                @include vendor-prefix(transition, all 0.2s);

                &:hover {
                    background-color: darken($gray-lighter, 3%);
                }
            }

            input {
                display: none;

                &:checked ~ label {
                    background-color: $brand-primary;
                    color: $white;
                    font-weight: 700;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &.tipo {
            padding-top: 0;
            padding-bottom: 2em;
        }
    }

    .description {
        text-align: center;
        padding-bottom: 2em;

        span {
            &.active {
                padding-bottom: 2em;
            }
        }
    }

    form {
        max-width: 557px;
        margin: 0 auto;

        .terms {
            width: 100%;
            float: left;
            margin: 15px 0;
            margin-bottom: 2em;
            text-align: center;

            label {
                float: none;

                a {
                    color: lighten($primary-color, 5%);

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.signup-success {
    .btn {
        width: 7em;
        display: block;
        margin: 0 auto;
        margin-top: 2em;
        text-align: center;
    }
}
