﻿.navbar {
    .navbar-header {
        height: 70px;
    }

    .navbar-toggle {
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .navbar-collapse {
        @include box-shadow(none);
    }

    li {
        a {
            @include breakpoint(md) {
                padding-top: 23px;
                padding-bottom: 23px;
            }
        }
    }
}

.navbar-brand {
    img {
        width: auto;
        height: 40px;
    }
}
