﻿.sidebar {
  display: none;
  background-color: lighten($site-background-color, 1.5%);
  border: 2px solid darken($site-background-color, 3%);
  margin-bottom: $padding-large-vertical * 2;
  margin-top: 15px;

  h3 {
    font-size: 18px;
  }

  .sidebar-block {
    padding: 10px;
    border-bottom: 1px solid darken($body-bg, 12%);

    h3 {
      margin-bottom: 15px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .sidebar-filter {
    margin-bottom: 15px;

    @include vendor-prefix(transition, all 0.3s);

    .page-container {
      width: 100%;
      padding: 15px;
      background-color: $background-color;
      @extend %box;
    }

    h3 {
      font-size: 1.3em;
    }

    .field {
      margin-bottom: $def-v-margin;
      @extend .cf;

      label {
        width: 100%;
        display: block;
        margin: 5px 0;
      }

      select {
        width: 100%;
        padding: 5px 10px;
      }

      @include breakpoint(md) {
        &.price {
          .col {
            &:last-child {
              padding-left: 5px;
            }

            &:first-child {
              padding-right: 5px;
            }
          }
        }
      }
    }

    .colors-field {
      display: none;

      .colors {

        ul {
          list-style: none;
        }
      }

      .switcher {
        width: 100%;
        height: 100%;
      }

      .color {
        float: left;
        margin-left: 5px;
        position: relative;

        .check {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          font-size: 10px;
          background-color: $green;
          color: $white;
          text-align: center;
          line-height: 20px;
          right: -5px;
          top: -1px;
          position: absolute;
          @include vendor-prefix(transition, all 0.1s);
          @include scale(0);
        }

        &.selected {
          .check {
            @include scale(1);
          }

          label {
            opacity: 1;
          }
        }

        label {
          width: 40px;
          height: 40px;
          border: 2px solid darken($white, 5%);
          border-radius: 20px;
          margin: 0;
          padding: 0;
          cursor: pointer;
          overflow: hidden;
          opacity: 0.7;
          @include transition(opacity 0.2s ease);

          &:hover {
            opacity: 1;
          }
        }

        input {
          display: none;
        }

        span {
          display: block;
          width: 100%;
          height: 38px;
          float: left;
        }

        .half {
          width: 50%;
        }

        .full {
          width: 100%;
        }
      }
    }
  }

  .sidebar-categories {

    ul {
      list-style: none;

      a, label {
        display: block;
        font-weight: normal;
      }

      .top {
        .caption, .sub a {
          color: $text-color;
          display: block;
          padding: 5px 10px;
          cursor: pointer;
        }

        a {
          display: block;
          text-decoration: none !important;

          &:hover {
            color: $brand-primary;
          }
        }

        .caption {
          display: block;

          .icon {
            @include transition(.2s ease);
            float: right;
            margin-top: 3px;
          }
        }

        &.has-sub {

          &:hover {
            .icon {
              color: $brand-primary;
            }
          }

          .sub {
            padding: 15px 0;
            padding-left: 15px;
            display: none;
          }

          .input:checked ~ .sub {
            display: block;
          }

          .input:checked ~ .icon {
            @include rotate(-180deg);
          }
        }
      }
    }
  }

  select.input-sm {
    line-height: 1;
    padding: 5px 10px !important;
  }

  @include breakpoint(md) {
    display: block;
   
  }

  &.sidebar-fixed {
    min-width: 280px;
    max-width: 300px;
    position: relative;
    margin-bottom: $padding-large-vertical * 3;

    h3 {
      margin: 0 !important;
      padding: 0;
    }

    .sidebar-toggle {
      display: block;
      margin: 0;
      cursor: pointer;

      h3 {
        float: left;
      }

      i {
        float: right;
        font-size: 28px;
        @include transition(.2s ease);
      }
    }

    .sidebar-block {
      padding: 10px 15px;
    }

    .caption {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .sidebar-dropdown {
      padding: 15px 20px;
      display: none;
      position: absolute;
      top: 100%;
      left: -2px;
      right: -2px;
      z-index: 100;
      background-color: lighten($site-background-color, 1.5%);
      border-left: 2px solid darken($site-background-color, 3%);
      border-right: 2px solid darken($site-background-color, 3%);
      border-bottom: 2px solid darken($site-background-color, 3%);
    }

    #sidebar-fixed-input:checked ~ .sidebar-block {
      .sidebar-dropdown {
        display: block;
      }

      .sidebar-toggle {
        i {
          @include rotate(-180deg);
        }
      }
    }
  }
}

.catalog-filter:checked ~ .sidebar {
  display: block;
}