.sevo-spinner-container {
  animation: rotate 2s linear infinite;
  -webkit-animation: rotate 2s linear infinite;

  .path {
    stroke-dasharray: 1, 150; /* 1%, 101% circumference */
    stroke-dashoffset: 0;
    stroke: $text-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    -webkit-animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150; /* 1%, 101% circumference */
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150; /* 70%, 101% circumference */
    stroke-dashoffset: -35; /* 25% circumference */
  }
  100% {
    stroke-dasharray: 90, 150; /* 70%, 101% circumference */
    stroke-dashoffset: -124; /* -99% circumference */
  }
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150; /* 1%, 101% circumference */
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150; /* 70%, 101% circumference */
    stroke-dashoffset: -35; /* 25% circumference */
  }
  100% {
    stroke-dasharray: 90, 150; /* 70%, 101% circumference */
    stroke-dashoffset: -124; /* -99% circumference */
  }
}