﻿@import "../vendors/bootstrap/_bootstrap.scss";
@import "reset.scss";
@import "vars.scss";

a {
    text-decoration: none;
    color: $primary-color;

    &:hover {
        text-decoration: none;
        color: lighten($primary-color, 10%);
    }
}


%boxBtn {
    background-color: lighten($primary-color, 10%);
}

%box {
    background-color: $white;
    border: 1px solid darken($site-background-color, 3%);
    border-bottom: 2px solid darken($site-background-color, 3%);
    border-radius: 2px;
}

.box {
    @extend %box;
}


@mixin hover-border($width, $color: darken($background-color, 10%)) {
    @include vendor-prefix(box-shadow, 0 0 0 $width $color);
}


#wrapper {
    width: 100%;
    min-height: 100%;
    // opacity: 0;
}

.left {
    float: left;
}

.right {
    float: right;
}

.cf {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.content {
    max-width: 960px;
    margin: 0 auto;

    &:after {
        content: "";
        height: 0;
        width: 0;
        clear: both;
        visibility: hidden;
    }
}

@mixin vendor-prefix($name, $argument) {
    -webkit-#{$name}: #{$argument};
    -ms-#{$name}: #{$argument};
    -moz-#{$name}: #{$argument};
    -o-#{$name}: #{$argument};
    #{$name}: #{$argument};
}


@function bottom-shadow($depth) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(0,0,0,0.1);

    @return 0 $primary-offset $blur $color;
}


@mixin shadow($depth) {
    @if $depth < 1 {
        box-shadow: none;
    }
    @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    }
    @else {
        -webkit-box-shadow: bottom-shadow($depth);
        -moz-box-shadow: bottom-shadow($depth);
        box-shadow: bottom-shadow($depth);
    }
}

.box {
    border: $border-color;
    background-color: $white;
}

::-moz-selection {
    background-color: lighten($primary-color, 5%);
    color: $white;
}

::selection {
    background-color: lighten($primary-color, 5%);
    color: $white;
}


#hp {
    display: none;
}

.success {
    text-align: center;

    i {
        font-size: 5em;
        color: $green;
    }

    .message {
        margin: 1.5em 0;
        font-size: 1.2em;
    }
}

.m-disabled {
    opacity: 0.2;
    pointer-events: none;
}
